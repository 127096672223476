<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient123" x1="0.5" x2="0.5" y2="1">
     <stop offset="0" stop-color="#4e14d1"/>
     <stop offset="1" stop-color="#00218e"/>
    </linearGradient>
   </defs>
   <g id="Twitch" transform="translate(-210 -448)">
    <rect :fill="background" data-name="Rectangle 21" height="60" id="Rectangle_21" transform="translate(210 448)" width="60"/>
    <path d="M2.719,2.25.7,7.4V28.446H7.868v3.8H11.9l3.8-3.8h5.826l7.835-7.835V2.25Zm23.96,17.016-4.48,4.48H15.033l-3.8,3.8v-3.8H5.183V4.935h21.5v14.33ZM22.2,10.085v7.828H19.513V10.085Zm-7.165,0v7.828H12.348V10.085Z" data-name="Icon awesome-twitch" id="Icon_awesome-twitch" style="fill: url(#linear-gradient123);" transform="translate(225.297 460.75)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>